'use strict'

###*
 # @ngdoc service
 # @name users.factory:UserRoleManager

 # @description

###
angular
  .module 'users'
  .factory 'UserRoleManager', [
    'Restangular'
    'UserPermissionsManager'
    'MundoDefaultManager'
    '$log'
    '_'
    (
      Restangular
      UserPermissionsManager
      MundoDefaultManager
      $log
      _
    )->
      UserRoleManagerBase = new MundoDefaultManager()

      UserRoleManagerBase.setUrl('roles')
      UserRoleManagerBase.setAutoTenant()
      UserRoleManagerBase.setNewObject(['label', 'comments', 'permissions', 'childRoles'])
      UserRoleManagerBase.setActions(['list', 'create', 'search', 'update', 'delete'])
      UserRoleManagerBase.setSearchFields(['label'])

      pluckData = (arrayToBePlucked, value) ->
        return _.pluck arrayToBePlucked, value

      UserRoleManagerBase.new = (data) ->
        data.permissions = pluckData data.permissions, 'permission'
        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UserRoleManagerBase.update = (id, data) ->

        data.permissions = pluckData data.permissions, 'permission'
        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))


      UserRoleManagerBase.getNewForm = ->
        inheritedPermList = []

        getInheritedPermissions = (childroles) ->
          inheritedPermList.splice(0,inheritedPermList.length)
          for role in childroles
            Restangular.service('services/roles/' + role + '/permissions').getList().then(
              (results) ->
                inheritedPermList.push permission.permission for permission in results
                #console.log(inheritedPermList)
              ,
              (error) -> $log.warn("couldn't reach services/roles/")
            )

        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'comments'
          name: 'comments'
          type: 'input'
          templateOptions:
            label: 'Description'
            placeholder: 'Description'
            required: false
            description: 'Description of the role'
        ,
          key: 'childRoles'
          name: 'childRoles'
          type: 'childrolesDropdown'
          validators: {
            update: (childroles) ->
              getInheritedPermissions(childroles)
              return true
          }
          templateOptions:
            label: 'Inherit From'
            multiple: true
            placeholder: 'Parents'
            required: false
            labelProp: 'label'
            options: @getFullList({'sort': 'permission,ASC'}).$object
        ,
          key: 'permissionFilters'
          name: 'permissionFilters'
          type: 'permissionFilters'
          templateOptions:
            label: 'permissionFilters'
        ,
          key: 'permissions'
          name: 'permissions'
          type: 'permissionlist'
          templateOptions:
            label: 'Permissions'
            placeholder: 'permissions'
            multiple: true
            labelProp: "permission"
            valueProp: "permission"
            options: UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).$object
            inheritedPermList: inheritedPermList
        ]

      UserRoleManagerBase.getEditForm = (data) ->

        permissionsArray = []
        UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).then (possiblePermissions) ->
          angular.forEach data.permissions, (value) ->
            permissionToAdd = _.filter possiblePermissions, (permission) ->
              return permission.permission == value
            if (permissionToAdd) && (angular.isArray permissionToAdd) && (permissionToAdd.length > 0)
              permissionsArray.push {'permission': permissionToAdd[0].permission}

        defaultChildRoleIds = (childroles) ->
          idlist = []
          idlist.push(child.id) for child in childroles
          getInheritedPermissions(idlist)
          return idlist

        inheritedPermList = []

        getInheritedPermissions = (childroles) ->
          inheritedPermList.splice(0,inheritedPermList.length)
          for role in childroles
            Restangular.service('services/roles/' + role + '/permissions').getList().then(
              (results) ->
                inheritedPermList.push permission.permission for permission in results
                #console.log(inheritedPermList)
              ,
              (error) -> $log.warn("couldn't reach services/roles/")
            )



        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'comments'
          name: 'comments'
          type: 'input'
          defaultValue: data.comments
          templateOptions:
            label: 'Description'
            placeholder: 'Description'
            required: false
            description: 'Description of the role'
        ,
          key: 'childRoles'
          name: 'childRoles'
          type: 'childrolesDropdown'
          defaultValue: defaultChildRoleIds(data.childRoles)
          validators: {
            update: (childroles) ->
              getInheritedPermissions(childroles)
              return true
          }
          templateOptions:
            label: 'Inherit From'
            multiple: true
            placeholder: 'Parents'
            required: false
            labelProp: 'label'
            options: @getFullList({'sort': 'permission,ASC'}).$object
        ,
          key: 'permissionFilters'
          name: 'permissionFilters'
          type: 'permissionFilters'
          templateOptions:
            label: 'Permission Filters'
        ,
          key: 'permissions'
          name: 'permissions'
          type: 'permissionlist'
          defaultValue: permissionsArray
          templateOptions:
            label: 'Permissions'
            required: false
            multiple: true
            labelProp: 'permission'
            valueProp: 'permission'
            options: UserPermissionsManager.getFullList({'sort': 'permission,ASC'}).$object
            childRolesKey: 'childRoles' #need this key to automatically check inherited permissions
            inheritedPermList: inheritedPermList
        ]

      UserRoleManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ]

      #permission needed to edit/delete an entity
      UserRoleManagerBase.editPermission = 'manage all MundoMosaUserBundle:Role entities'

      UserRoleManagerBase
  ]
